import { IconButton, Typography, Box  } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const BackArrowButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <IconButton 
        onClick={handleGoBack}
        sx={{
            position: 'fixed',
            top: '2%',
            left: '3%',
            zIndex: 1000,
            background: "#35A6AE",
            color: "white",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            borderRadius: "50px",
            "&:hover":{
                background: "#1c4445",
                color:'white'
            }
        }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ArrowBack />
        <Typography variant="body1" ml={1}>Go Back</Typography>
      </Box>
    </IconButton>
  );
};

export default BackArrowButton;
