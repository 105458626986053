import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import nxusLogo from "../../assets/images/connectLogo.png";
import LanguageToggle from "../../../LanguageToggle";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { Divider } from "@mui/material";
const pages = ["header.products", "header.pricing", "header.blog"];
function ResponsiveAppBar() {
  const { t } = useTranslation();
  const { authenticated } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const darkTheme = createTheme({
    shadows: "none",
    color: "#333",
    palette: {
      primary: {
        main: "#FFFFFF00",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static" className="headerBg">
        <Container maxWidth={false} sx={{ maxWidth: "100%", padding: "0px " }}>
          <Toolbar
            sx={{
              minHeight: "48px !important",
              justifyContent: { xs: "space-between", md: "space-between" },
              width: "100%",
              padding: { xs: "0 15px", sm: "0px !important" },
            }}
          >
            <Box
              display={{ xs: "none", md: "flex" }}
              alignItems="center"
              flexBasis="33%"
              flexWrap="wrap"
            >
              <Box display="flex" alignItems="center" pr={1}>
                <IconButton
                  sx={{
                    padding: "4px",
                    backgroundColor: "#D1D0D0",
                    marginRight: "6px",
                  }}
                >
                  <EmailIcon sx={{ color: "#35A6AE", fontSize: "1.3rem" }} />
                </IconButton>
                <Typography fontSize={12}>hello@connectrx.com</Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "#D7D7D7 !important",
                }}
              />
              <Box display="flex" alignItems="center" pl={1}>
                <IconButton
                  sx={{
                    padding: "4px",
                    backgroundColor: "#D1D0D0",
                    marginRight: "6px",
                  }}
                >
                  <PhoneInTalkIcon
                    sx={{ color: "#35A6AE", fontSize: "1.3rem" }}
                  />
                </IconButton>
                <Typography fontSize={12}>+01 234 456 8882</Typography>
              </Box>
            </Box>
            <Box
              flexBasis="33%"
              justifyContent="center"
              className="landingPageheader1"
              onClick={() => {
                navigate("/");
              }}
              sx={{ cursor: "pointer" }}
            >
              <img src={nxusLogo} alt="nxusLogo" />
            </Box>
            <Box
              flexBasis="33%"
              className="landingPageheader"
              onClick={() => {
                navigate("/");
              }}
              sx={{ cursor: "pointer" }}
            >
              <img src={nxusLogo} className="secondLogo" alt="nxusLogo" />
            </Box>

            <Box
              display="flex"
              justifyContent="flex-end"
              flexBasis="33%"
              p={{ xs: "0px", sm: "16px 0" }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "40px !important",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  border: "1px solid black",
                  marginRight: { xs: "7px", sm: "20px" },
                  padding: "0px 12px !important",
                  "@media screen and (max-width: 576px)": {
                    fontSize: "11px",
                    padding: "0px 4px !important",
                    whiteSpace: "nowrap !important",
                  },
                }}
                onClick={() => {
                  if (authenticated && authenticated?.email) {
                    navigate("/login");
                  } else {
                    navigate("/authenticate");
                  }
                }}
              >
                {t("header.login")}
              </Button>
              <LanguageToggle
                className={"language-toggle"}
                langColor="#00000"
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default ResponsiveAppBar;
