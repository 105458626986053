import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./businessHeader";
import "./business.scss";
import Star from "../../assets/businessPage/Star.svg";
import Illustration from "../../assets/businessPage/Illsituration.png";
import MaskMan from "../../assets/businessPage/MaskMan.png";
import FooterImg from "../../assets/businessPage/Footer.png";
import { Divider, Stack, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import logo from "../../assets/images/connectLogo.png";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import FaqResponsiveHeader from "./faqHeader";
import Footer from "../../shared/components/footer";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useTranslation } from "react-i18next";
import AboutBg from "../../assets/connectRxImages/AboutBg.svg";
import AboutImage1 from "../../assets/connectRxImages/AboutImage1.svg";
import AboutImage2 from "../../assets/connectRxImages/AboutImage2.svg";
import AboutImage3 from "../../assets/connectRxImages/AboutImage3.svg";
import bulletsIcon from "../../assets/connectRxImages/Star 1.svg";
import MedicensImage from "../../assets/connectRxImages/AdboutUsFooter.svg";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const salesAndPurchasesBullets = [
    "landingPage.bp1",
    "landingPage.bp2",
    "landingPage.bp3",
    "landingPage.bp4",
    "landingPage.bp5",
  ];

  return (
    <>
      <ResponsiveAppBar />
      <BackArrowButton />
      <Box
        sx={{
          backgroundImage: `url(${AboutBg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "126px 0",
        }}
      >
        <Box
          display={"flex"}
          flexWrap='wrap'
          flexDirection={"column"}
          alignItems={"center"}
          gap={{ xs: "10px", sm: "22px", md: "28px" }}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography
              fontSize={{ xs: "28px", sm: "36px", md: "48px" }}
              color={"white"}
            >
              About Us
            </Typography>
            <Divider sx={{ border: "2px solid white", width: "67%" }} />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={{ xs: "10px", sm: "20px" }}
          >
            <Typography
              variant={{ xs: "h6", sm: "h6", md: "h5" }}
              color={"white"}
            >
              Home
            </Typography>
            <Typography color={"white"}>|</Typography>
            <Typography
              variant={{ xs: "h6", sm: "h6", md: "h5" }}
              color={"white"}
            >
              About Us
            </Typography>
          </Box>
        </Box>
      </Box>
      <Container>
        <Box
          display={"flex"}
          flexWrap='wrap'
          alignItems={"center"}
          justifyContent={"center"}
          position={"relative"}
          margin={{ xs: "30px 0", sm: "50px 0" }}
          padding={{ xs: "10px", sm: "20px", md: 0 }}
        >
          <Box width={{ xs: "100%", sm: "80%", md: "50%" }}>
            <img
              src={AboutImage1}
              alt=''
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box
            position={"relative"}
            margin={{
              xs: "20px 0px 0px 0px",
              sm: "20px 0  0 0px",
              md: "20px 0  0 -100px",
            }}
            sx={{
              borderRadius: "15px",
              background: "#35A6AE",
              boxShadow: "0px 2px 21.3px 0px rgba(0, 0, 0, 0.07)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "6px",
              padding: { xs: "14px 15px", sm: "20px 30px" },
            }}
          >
            <Typography variant='h4' color={"white"}>
              Who are We?
            </Typography>
            <Typography variant='p' maxWidth={" 555px"} color={"white"}>
              ConnectRx is a pioneer in the healthcare industry, bridging gaps
              between Regulated Medical Professionals through a meticulously
              curated B2B exchange platform. We serve as the connectrx between
              technology and healthcare, integrating a digital platform that
              empowers professionals to list, purchase, and sell surplus medical
              supplies and near-expiry medication. Our multi-layered
              pre-screening process ensures members are verified and
              authenticated, offering a high degree of confidence to every user.
              ConnectRx uses a real-time payment solution, IntraPay, and a
              secure delivery system that provides an end-to-end solution for
              safe and efficient trading of medical supplies across Canada.
              ConnectRx transcends the realm of a simple B2B marketplace; we
              represent a collective that fosters trust, promotes savings, and
              preserves profit for all involved.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box
          display={"flex"}
          flexWrap='wrap'
          alignItems={"center"}
          justifyContent={"center"}
          margin={{ xs: "30px 0", sm: "50px 0" }}
          padding={{ xs: "10px", sm: "20px", md: 0 }}
        >
          <Box
            margin={{
              xs: "20px 0px 0px 0px",
              sm: "20px 0px 0px 0px",
              md: "30px -100px 0px 0px",
            }}
            sx={{
              borderRadius: "15px",
              background: "#fff",
              boxShadow: "0px 2px 21.3px 0px rgba(0, 0, 0, 0.07)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "6px",
              padding: { xs: "14px 15px", sm: "20px 30px" },
              position: "relative",
            }}
          >
            <Typography variant='h4' color={"#35A6AE"}>
              Our Approach
            </Typography>
            <Typography variant='p' maxWidth={"555px"} color={"#191718"}>
              At ConnectRx, we are committed to creating a secure, efficient and
              reliable platform for our community of Regulated Medical
              Professionals. Our robust pre-screening procedure includes a
              thorough examination of licenses, confirmation interviews, and
              verification with regulatory bodies. Our platform is designed to
              foster confidence and trust, enabling our users to buy, sell, and
              list surplus medical supplies and medications securely. We aim to
              cultivate an ecosystem where surplus medications and equipment are
              not discarded, but instead find new homes, aiding pharmacies in
              managing their inventories efficiently while making crucial
              savings. Our goal is to assist healthcare providers in preserving
              their profits, even turning potential losses into gains, and in
              certain instances, enabling the provision of affordable medication
              to vulnerable patients.
            </Typography>
          </Box>
          <Box
            width={{ xs: "100%", sm: "80%", md: "50%" }}
            marginTop={{ xs: "20px", sm: "20px", md: "0" }}
          >
            <img
              src={AboutImage2}
              alt=''
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Box>
      </Container>
      <Container>
        <Grid container marginBottom={"60px"}>
          <Grid item xs={12} md={4} lg={6}>
            <img
              src={MedicensImage}
              style={{ width: "100%", height: "100%" }}
              alt='MedicensImage'
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={6}
            padding={{ xs: "15px 20px", sm: "30px !important" }}
            backgroundColor='#35A6AE'
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: 1, sm: 2 }}
          >
            <Box display='flex' flexDirection='column' gap={{ xs: 1, sm: 2 }}>
              <Typography
                fontSize={{ xs: "20px", sm: "36px" }}
                fontWeight='700'
                color='#fff'
              >
                Why ConnectRx?
              </Typography>
              <Typography
                fontSize={{ xs: "20px", sm: "30px" }}
                fontWeight='600'
                color='#fff'
                lineHeight='35px'
              >
                In this Industry, Every Second, Every Dollar Counts...
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              gap={{ xs: 1, sm: 2 }}
              padding={{ xs: "0", sm: "24px 30px 0px 0px" }}
            >
              {salesAndPurchasesBullets?.map((el, index) => (
                <Box
                  display='flex'
                  alignItems={"flex-start"}
                  gap={"7px"}
                  key={index}
                >
                  <img
                    src={bulletsIcon}
                    style={{ paddingTop: "3px" }}
                    alt='bulletsIcon'
                  />
                  <Typography
                    fontSize={{ xs: "14px", sm: "16px" }}
                    color='#fff'
                  >
                    {t(el)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default About;
