import React, { useEffect, useState } from "react";
import Footer from "../../shared/components/footer";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useNavigate } from "react-router-dom";
import { redirectPath } from "../../helpers/redirectPath";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FaqResponsiveHeader from "./faqHeader";
import { Input, Typography, Box, Container } from "@mui/material";
import Pagination from "../../shared/components/Pagination";
// import SearchIcon from "@mui/icons-material/Search";
// import Vactor from "../../assets/businessPage/Vactor.svg";
import { useTranslation } from "react-i18next";
import ResponsiveAppBar from "./businessHeader";
import Icon1 from "../../assets/businessPage/crossCirclefill.svg";
import SliderFaq from "../swiperFaq/SliderFaq";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";

const Faq = () => {
  const { t } = useTranslation();
  const itemsPerPage = 5;
  const dummyFAQs = [
    {
      question: "faq.question1",
      answer: "faq.answer1",
    },
    {
      question: "faq.question2",
      answer: "faq.answer2",
    },
    {
      question: "faq.question3",
      answer: "faq.answer3",
    },
    {
      question: "faq.question4",
      answer: "faq.answer4",
    },
    {
      question: "faq.question5",
      answer: "faq.answer5",
    },
    {
      question: "faq.question6",
      answer: "faq.answer6",
    },
    {
      question: "faq.question7",
      answer: "faq.answer7",
    },
    {
      question: "faq.question8",
      answer: "faq.answer8",
    },
    {
      question: "faq.question9",
      answer: "faq.answer9",
    },
    {
      question: "faq.question10",
      answer: "faq.answer10",
    },
  ];
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (value) => {
    console.log("Searching for:", value);
  };

  const faqItemsToDisplay = dummyFAQs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <ResponsiveAppBar />
      <BackArrowButton/>
      <Box
        sx={{
          background: "#35A6AE",
          minHeight: "432px",
          paddingTop: { xs: "91px", sm: "70px" },
        }}
      >
        <Container maxWidth={false}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={"10px"}
            width={{ xs: "95%", sm: "55%" }}
            margin={"auto"}
          >
            <Typography
              fontSize={{ xs: "28px", sm: "34px", md: "35px" }}
              fontWeight={"700"}
              color="#ffff"
            >
              {t("contactUs.title")}
            </Typography>
            <Input
              id="search-input"
              placeholder={t("faq.askQuestion")}
              disableUnderline
              sx={{
                width: "100%",
                border: "1px solid #fff",
                color: "#fff",
                padding: "7px 20px",
                borderRadius: "4px",
              }}
            />
          </Box>
        </Container>
      </Box>
      <SliderFaq />
      <Box textAlign="center" pt={{ xs: 2, sm: 8 }}>
        <Typography
          fontSize={{ xs: 24, sm: 30, md: 40 }}
          fontWeight={500}
          mb={2}
        >
          {t("faq.generalQuestions")}
        </Typography>
        <Typography
          fontSize={{ xs: 15, sm: 16, md: 18 }}
          fontWeight={400}
          color="#70747E"
          sx={{ width: { xs: "95%", sm: "60%" } }}
          margin="0 auto 40px "
        >
          {t("contactUs.Details")}
        </Typography>
      </Box>
      <Container>
        <Box
          sx={{
            padding: {
              xs: "10px 0px",
              sm: "10px 20px",
            },
            width: { xs: "100%", sm: "85%", md: "85%" },
            margin: " 0 auto 50px",
          }}
          borderRadius="40px"
          backgroundColor="#E1F2F3"
        >
          {faqItemsToDisplay.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent !important",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                aria-controls={`faq-panel-${index}`}
                id={`faq-header-${index}`}
                sx={{ padding: "10px 20px" }}
              >
                <Typography fontWeight={600} width={{ xs: "100%", md: "70%" }}>
                  {t(faq?.question)}
                </Typography>
                <Box display="flex" style={{ marginLeft: "auto" }}>
                  <Typography
                    color="#03AD54"
                    fontSize="14px"
                    sx={{
                      textTransform: "capitalize",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    {t("faq.showAnswer")}
                  </Typography>
                  <img src={Icon1} className="cusImg" />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{t(faq?.answer)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* <Stack spacing={2} sx={{ alignItems: "center", marginTop: "40px" }}>
              <Pagination
                totalCount={Math.ceil(dummyFAQs.length / itemsPerPage)}
                page={currentPage}
                onPageChange={handleChangePage}
              />
            </Stack> */}
        </Box>
      </Container>

      <NewsLetter />
      <Footer />
    </>
  );
};
export default Faq;
