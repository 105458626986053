import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import ForgetPassword from "../pages/forgetPassword";
import ResetPassword from "../pages/resetPassword";
import Signup from "../pages/Signup";
import AddPharmacy from "../pages/addStore";
import Signin from "../pages/Signin";
import VerifyOtp from "../pages/verifyOtp";
import NexusLandingPage from "../modules/nexusLandingPage/index";
import VerifyDocument from "../pages/verifyDocument";
import ProductDetail from "../pages/productDetail";
import ViewCart from "../pages/viewCart";
import Checkout from "../pages/checkout";
import Wishlist from "../pages/wishlist";
import UpdatePassword from "../pages/updatePassword";
import { useSelector } from "react-redux";
import CreatePassword from "../pages/members/CreatePassword";
import QrScan from "../shared/components/qr-scan";
import NexusBusiness from "../pages/businessPage";
import Pages from "../pages";
import Business from "../pages/businessIndex";
import UnderReview from "../pages/Signin/underReview";
import BasicAuth from "../pages/BasicAuth";
import ProductListing from "../pages/product-listing";
import Watchlist from "../pages/watchlist";
import CategoryListing from "../pages/category-listing";
import PrivacyPolicy from "../pages/businessPage/privacy-policy";
import TermAndConditions from "../pages/businessPage/termAndConditions";
import AboutUs from "../pages/businessPage/about";
import Faq from "../pages/businessPage/faq";
import ContactUs from "../pages/businessPage/contactUs";
import Pricing from "../pages/businessPage/pricingPage/pricing";
import Blog from "../pages/businessPage/blog/blog";
import BlogDetail from "../pages/businessPage/blog/blogDetail";
import Testimonial from "../pages/businessPage/testimonials/testimonials";
import WhyUs from "../pages/businessPage/whyUs/whyUs";
import Products from "../pages/businessPage/product/product";
import Approach from "../pages/businessPage/approach/approach";
import Queries from "../pages/BasicAuth/queries";
import withDynamicMeta from "../../MetaTags";
import { metaConfig } from "../shared/utils/utilities";

/**
 * Application main router component
 *
 * contains all the available routes and components in the application
 */

function NotFoundRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <Navigate to={redirectTo} />
  ) : (
    <Navigate to={redirectTo} />
  );
}

function PrivateRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
}

function BasicPrivateRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
}

function BusinessPrivateRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
}

const Router = () => {
  const { user, authenticated } = useSelector((state) => state.auth);
  const { isLoggedIn, allowedPages } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  if (loading) {
  } else {
    return (
      <Routes>
        <Route
          path="/watchlist"
          element={
            <BasicPrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/watchlist" element={<Watchlist />} />
        </Route>

        <Route
          exact
          path="/marketplace"
          element={
            <BasicPrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/marketplace" element={<NexusLandingPage />} />
        </Route>
        <Route path="/wishlist" element={<Wishlist />} />
        <Route
          path="/productlisting"
          element={
            <BasicPrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/productlisting" element={<ProductListing />} />
        </Route>
        <Route
          path="/categorylisting"
          element={
            <BasicPrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/categorylisting" element={<CategoryListing />} />
        </Route>
        <Route
          path="/bus/:view"
          element={
            <BusinessPrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/bus/:view" element={<Business />} />
        </Route>
        <Route
          path="/dash/:view"
          element={
            <PrivateRoute
              redirectTo="/login"
              isLoggedIn={user && user.token && user.email ? true : false}
            />
          }
        >
          <Route path="/dash/:view" element={<Pages />} />
        </Route>
        <Route element={<UpdatePassword />} path="/dash/updatePassword" exact />

        <Route exact path="/products/:id/:din" element={<ProductDetail />} />
        <Route exact path="/viewcart" element={<ViewCart />} />
        <Route exact path="/checkout" element={<Checkout />} />

        <Route exact path="/authenticate" element={<BasicAuth />} />
        <Route exact path="/login" element={<Signin />} />
        <Route exact path="/" element={<NexusBusiness />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route
          exact
          path="/term-&-conditions"
          element={<TermAndConditions />}
        />
        <Route exact path="/verifyDocument" element={<VerifyDocument />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/qr/businesscard" element={<Queries />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/blog/:slug" element={<BlogDetail />} />
        <Route exact path="/testimonial" element={<Testimonial />} />
        <Route exact path="/whyUs" element={<WhyUs />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/approach" element={<Approach />} />

        <Route exact path="/verifyDocument/:id" element={<VerifyDocument />} />
        <Route exact path="/qr-scanner/:token" element={<QrScan />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/addPharmacy" element={<AddPharmacy />} />
        <Route exact path="/verifyOtp" element={<VerifyOtp />} />
        <Route exact path="/forgotpassword" element={<ForgetPassword />} />
        <Route exact path="/underReview" element={<UnderReview />} />
        <Route exact path="/resetPassword/:id" element={<ResetPassword />} />
        <Route exact path="/createPassword/:id" element={<CreatePassword />} />

        <Route
          path="*"
          element={
            <NotFoundRoute
              isLoggedIn={isLoggedIn}
              redirectTo={
                authenticated && authenticated?.email
                  ? allowedPages.length > 0
                    ? `/bus/dashboard`
                    : location?.pathname?.includes("bus")
                    ? "/dash/store-dashboard"
                    : "/marketplace"
                  : "/authenticate"
              }
            />
          }
        />
      </Routes>
    );
  }
};

export default withDynamicMeta(Router, metaConfig);
