import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/modules/free-mode/free-mode.min.css";
// import "swiper/modules/thumbs/thumbs.min.css";
import "../../modules/nexusLandingPage/nexus.scss";

const BusinessPageCard = ({ el, setCount }) => {
  return (
    <Card
      raised
      sx={{
        border: "none",
        borderRadius: "40px",
        backgroundColor: "transparent !important",
      }}
    >
      <Box
        display="flex"
        className="carousel-img-container"
        justifyContent="center"
      >
        {el?.imageCover ? (
          <img src={el?.imageCover?.full_image} />
        ) : (
          <Box className="cusCardProductName">
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {el?.product_name}
            </Typography>
          </Box>
        )}
      </Box>
      <CardContent sx={{ padding: "16px 20px !important" }}>
        <Typography
          sx={{
            display: { xs: "none", sm: "block" },
            fontSize: "16px",
          }}
          className="text-ellipses latest-product-text"
          variant="body1"
        >
          {el?.product_name}
        </Typography>
        <Typography
          sx={{
            display: { xs: "block", sm: "none" },
            fontSize: "16px",
            height: "44px",
            overflow: "hidden",
          }}
          className="latest-product-text"
          variant="body1"
        >
          {el?.product_name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BusinessPageCard;
