// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/login-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 600px) {\n  .loginStyle {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover; } }\n", "",{"version":3,"sources":["webpack://src/app/pages/Signin/signin.scss"],"names":[],"mappings":"AACI;EADJ;IAEI,yDAAyD;IACzD,4BAA4B;IAC5B,sBAAsB,EAAA,EAEzB","sourcesContent":[".loginStyle{\n    @media (max-width: 600px) {\n    background-image: url(\"../../assets/images/login-bg.png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
