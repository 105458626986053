import React from "react";
import "./footer.scss";
import logo from "../../../assets/connectRxImages/ConnectrxWhiteLogo.svg";
import { Box, Container, Divider, Grid, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import FaceBook from "../../../assets/connectRxImages/FaceBook.svg";
import Instagram from "../../../assets/connectRxImages/Instagram.svg";
import Twitter from "../../../assets/connectRxImages/Twiter.svg";
import Linkedin from "../../../assets/connectRxImages/linkedIn.svg";
import Paypal from "../../../assets/connectRxImages/paypalIcon.svg";
import Visa from "../../../assets/connectRxImages/VisaIcon.svg";
import CardIcon from "../../../assets/connectRxImages/FooterFrame.svg";
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Box
      className="footer-bg"
      sx={{
        padding: "100px 0 0",
        backgroundColor: "#225C63",
      }}
    >
      <Container>
        <Grid
          container
          alignItems={"flex-start"}
          spacing={{ xs: "10", sm: "8", md: "0" }}
          gap={{ xs: "30px", sm: "0px" }}
          marginBottom={"20px"}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            // alignItems={"center"}
            gap={"15px"}
          >
            <Box
              onClick={() => {
                navigate("/");
              }}
              sx={{ cursor: "pointer" }}
            >
              <img src={logo} className="logo" alt="logo" width={"180px"} />
            </Box>
            <Box display={"flex"} gap={"10px"}>
              <img
                src={FaceBook}
                alt="FaceBook"
                height="25px"
                width="25px"
                style={{
                  padding: "4px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              />
              <img
                src={Instagram}
                alt="Instagram"
                height="25px"
                width="25px"
                style={{
                  padding: "4px 0px",
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  border: "1px solid white",
                }}
              />
              <img
                src={Twitter}
                alt="Twitter"
                height="25px"
                width="25px"
                style={{
                  padding: "4px",
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  border: "1px solid white",
                }}
              />
              <img
                src={Linkedin}
                alt="Linkedin"
                height="25px"
                width="25px"
                style={{
                  // marginLeft: "8px",
                  padding: "4px 0px",
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  border: "1px solid white",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"15px"}
          >
            <Typography fontSize={"20px"} color={"#fff"} fontWeight={"700"}>
              Quick Links
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
              alignItems={"flex-start"}
            >
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => navigate("/about-us")}
              >
                About Us
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => navigate("/term-&-conditions")}
              >
                Term Of Services
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"15px"}
          >
            <Typography fontSize={"20px"} color={"#fff"} fontWeight={"700"}>
              Company
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
              alignItems={"flex-start"}
            >
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61552489185918"
                  )
                }
              >
                Facebook
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() =>
                  window.open(" https://www.instagram.com/unio_rx/")
                }
              >
                Instagram
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => window.open("https://twitter.com/RxUnio72850")}
              >
                Twitter
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() =>
                  window.open("https://www.linkedin.com/company/101019391/")
                }
              >
                Linkedin
              </Link>
              <Link
                component="button"
                fontSize={"16px"}
                color={"#fff"}
                fontWeight={"400"}
                className="link"
                onClick={() => {
                  navigate("/faq");
                }}
              >
                FAQ
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"15px"}
          >
            <Typography fontSize={"20px"} color={"#fff"} fontWeight={"700"}>
              About Us
            </Typography>
            <Typography
              fontSize={"16px"}
              color={"#fff"}
              fontWeight={"400"}
              className="link"
            >
              ConnectRx is a B2B exclusive exchange platform that connects
              Regulated Medical Professionals through a highly screened and
              secure platform.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ maxWidth: "100%", borderColor: "white !important" }} />

      <Container>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={{ xs: "10px 8px", sm: "10px 14px" }}
        >
          <Typography
            fontSize={{ xs: "14px", sm: "16px" }}
            color={"#fff"}
            fontWeight={"400"}
          >
            ConnectRx © 2023 All Rights Reserved{" "}
          </Typography>
          <Box display={"flex"} gap={"10px"} className="bottom_image">
            <img src={Paypal} alt="" />
            <img src={Visa} alt="" />
            <img src={CardIcon} alt="" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
